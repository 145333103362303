<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-card class="authBox">
                    <div slot="header">
                        <span>用户列表</span>
                    </div>
                    <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar reviewBookList" round align="left"
                        ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                        :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                        :data="distributionUserList">
                        <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                        <vxe-column field="label" title="用户名" width="200" :show-overflow="'tooltip'"></vxe-column>
                        <vxe-column title="操作" min-width="200">
                            <template v-slot="scope" #default="{ row }">
                                <!-- click触发 -> 后端调数据 -> 前端显示 index/vxe-modal -->
                                <el-button size="small" type="text" @click="getDistributionBatchList(scope.row)">查看</el-button>
                            </template>
                        </vxe-column>
                    </vxe-table>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="authBox selectedAuth">
                    <div slot="header" class="clearfix">
                        <span>批次任务</span>
                    </div>
                    <div>
                        <vxe-table :header-cell-style="headerCellStyle" :cell-style='cellStyle' border stripe round
                                highlight-hover-row align="center" ref="myTable" :data="batchDataList"
                                class="mytable-scrollbar financialList" show-footer
                                :checkbox-config="{trigger: 'row', highlight: true, range: true}" :export-config="{}" :print-config="{}"
                                >
            <!--                <vxe-column type='seq' title="序号" width="6%" :show-overflow="'tooltip'"></vxe-column>-->
                            <vxe-column type='checkbox' title="全选" width="6%" :show-overflow="'tooltip'"></vxe-column>
                            <vxe-column field="institutionName" title="单位名称" width="30%" :show-overflow="'tooltip'"></vxe-column>
                            <vxe-column field="batchName" title="批次名称" width="30%" :show-overflow="'tooltip'"></vxe-column>
                            <vxe-column field="createTime" title="创建时间" width="10%" :show-overflow="'tooltip'" sortable></vxe-column>
                            <vxe-column field="fileDatetime" title="归档时间" width="10%" :show-overflow="'tooltip'" sortable>
            <!--                    <template #default="{ row }">-->
            <!--                        {{row.fileDatetime!=null?row.fileDatetime:'未归档'}}-->
            <!--                    </template>-->
                            </vxe-column>
                            <vxe-column field="isFiled" title="归档状态" width="14%" :show-overflow="'tooltip'" sortable>
                                <template #default="{ row }">
                                    {{row.isFiled==1?'已归档':'未归档'}}
                                </template>
                            </vxe-column>
                        </vxe-table>
                    </div>
                </el-card>
                <el-row style="margin-top: 10px">
                  <div>
                    <el-popconfirm title="是否确认移除？" @confirm="removeBatch">
                      <el-button type="primary" size="small" slot="reference">移除批次</el-button>
                    </el-popconfirm>
                  </div>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import XLSX from 'xlsx'
    import batchManagement from "@/api/batchManagement";
    export default {
        data() {
            return {
                distributionUserList: [{
                    id:1 ,
                    label: 'A'
                }],
                checkStrictly: true,
                loading: true,
                selectedUserId: '',
                batchDataList: [],
            }
        },
        created() {
            this.getDistributionUserList()
        },
        methods: {
            // 获取所有用户
            getDistributionUserList(){
                this.$api.batchManagement.getDistributionUserList()
                    .then(res => {
                        if(res.data.code == 200){
                            this.distributionUserList = res.data.data;
                            this.loading = false;
                            console.log(this.distributionUserList);
                        }else{
                            this.$message.warning('服务器维护')
                        }
                    })
            },
            // 获取用户批次任务
            getDistributionBatchList(row){
                this.selectedUserId = row.id;
                let params ={
                    // institutionName:this.search.institutionName,
                    // batchName:this.search.batchName,
                    institutionId: null,
                    batchId: null,
                    // 仅显示未归档的批次
                    sysUserId:row.id,
                    isFiled: null
                };
                console.log(params);
                this.$api.batchManagement.getDistributionBatchList(params)
                    .then(res => {
                        if(res.data.code == 200){
                            console.log(res.data);
                            this.batchDataList = res.data.data;
                        }else{
                            this.$message.warning('服务器维护');
                        }
                    })
            },
            removeBatch(){
                let ids = [];
                let checkBox = this.$refs.myTable.getCheckboxRecords();
                for(let i=0;i<checkBox.length;i++){
                    ids.push(checkBox[i].batchId);
                }
                console.log(ids);
                console.log(this.selectedUserId);
                console.log(ids.length);
                if(ids.length == 0){
                    this.$message.warning('请至少选择一个批次')
                }else{
                    let params = {
                        "sysUserId": this.selectedUserId,
                        "distributionBatchList": ids
                    }
                    console.log(params);
                    this.$api.batchManagement.deleteBatchDistribution(params)
                        .then(res => {
                            if(res.data.code == 200){
                                this.$message.success('移除成功');
                                let params2 = {

                                    institutionId: null,
                                    batchId: null,
                                    sysUserId: this.selectedUserId,
                                    isFiled: 0
                                };
                                this.$api.batchManagement.getDistributionBatchList(params2)
                                    .then(res => {
                                        if(res.data.code == 200){
                                            console.log(res.data);
                                            this.batchDataList = res.data.data;
                                        }else{
                                            this.$message.warning('服务器维护');
                                        }
                                    })
                            }else{
                                this.$message.warning('服务器维护')
                            }
                        })
                }
            }
        },
        components: {

        }
    }
</script>

<style scoped>

</style>

<style>
    /* .selectedAuth .el-card__body {
        padding: 0px;
        overflow: auto;
    }

    .authBox .el-card__body {
        height: calc(100vh - 350px);
        overflow: auto;
    } */
</style>