<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-card class="authBox">
                    <div slot="header">
                        <span>批次列表</span>
                    </div>
                    <div>
                        <el-form :inline="true" class="demo-form-inline">
                            <el-form-item label="单位:">
                                <el-select v-model="search.institutionId" size="medium" filterable  clearable @change="handleInstitution" placeholder="请选择单位" class="form-line-item">
                                    <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                                            :value="item.institutionId"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="批次:">
                                <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                                        class="form-line-item" :disabled='batchShow'>
                                    <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                                            :value="item.batchId"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="归档:">
                                <el-select v-model="search.isFiled" size='medium' clearable placeholder="请选择是否归档"
                                        class="form-line-item" >
                                    <el-option v-for="(item,index) in isFiledList" :key='item.key' :label="item.value"
                                            :value="item.key"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item style="margin-left:10px;">
                                <el-button type="primary" size="small" icon="el-icon-search" @click="getDistributionBatchList">
                                        搜索
                                </el-button>
                                <el-button type="primary" size="small" icon="el-icon-refresh" @click="resetHandle">
                                        重置
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar reviewBookList" round align="left"
                        ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                        :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                        :data="distributionBatchList">
                        <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                        <vxe-column field="institutionName" title="单位名称" width="200" :show-overflow="'tooltip'"></vxe-column>
                        <vxe-column field="batchName" title="批次名称" width="200" :show-overflow="'tooltip'"></vxe-column>
                        <vxe-column field="isFiled" title="是否归档" width="200" :show-overflow="'tooltip'">
                            <template #default = "{row}">
                                {{row.isFiled == 1 ? '已归档':'未归档'}}
                            </template>
                        </vxe-column>
                        <vxe-column title="操作" min-width="200">
                            <template v-slot="scope" #default="{ row }">
                                <!-- click触发 -> 后端调数据 -> 前端显示 index/vxe-modal -->
                                <el-button size="small" type="text" @click="getDistributionUsers(scope.row)">查看</el-button>
                            </template>
                        </vxe-column>
                    </vxe-table>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="authBox selectedAuth">
                    <div slot="header" class="clearfix">
                        <span>批次任务</span>
                    </div>
                    <div>
                        <vxe-table :header-cell-style="headerCellStyle" :cell-style='cellStyle' border stripe round
                                highlight-hover-row align="center" ref="myTable" :data="distributionUserList"
                                class="mytable-scrollbar financialList" show-footer
                                :checkbox-config="{trigger: 'row', highlight: true, range: true}" :export-config="{}" :print-config="{}"
                                >
            <!--                <vxe-column type='seq' title="序号" width="6%" :show-overflow="'tooltip'"></vxe-column>-->
                            <vxe-column type='checkbox' title="全选" width="30%" :show-overflow="'tooltip'"></vxe-column>
                            <vxe-column field="sysUsername" title="用户名称" width="70%" :show-overflow="'tooltip'"></vxe-column>
                        </vxe-table>
                    </div>
                    <div>
                        <el-popconfirm title="是否确认移除？" @confirm="removeUser">
                            <el-button type="primary" size="small" slot="reference">移除用户</el-button>
                        </el-popconfirm>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import XLSX from 'xlsx'
    import batchManagement from "@/api/batchManagement";
    export default {
        data() {
            return {
                distributionBatchList:[
                    {
                        id: 1,
                        institutionName: 'C',
                        batchName: 'A',
                        isDistributed: 0
                    },{
                        id: 2,
                        institutionName: 'D',
                        batchName: 'B',
                        isDistributed: 1
                    }
                ],
                distributionUserList:[],
                institutionList: [],
                batchList: [],
                isFiledList:[
                    {key:0,value:"未归档"},
                    {key:1,value:"已归档"},
                ],
                search: {
                    institutionId:'',
                    batchId:"",
                    institutionName: '',
                    batchName: '',
                    isFiled: '',
                },
                checkStrictly: true,
                loading: false,
                selectedbatchId: '',
            }
        },
        created() {
            this.getInstitution();
            this.getDistributionBatchList()
        },
        methods: {
            //获取送审单位
            getInstitution() {
                let params = {
                    isFiled: null
                }
                console.log(params);
                this.$api.schoolCustom.getbatchDistributionInstitution(params)
                    .then(res => {
                        console.log(res);
                        this.institutionList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning('接口错误');
                    })
            },
            //获取送审单位的批次
            getBatch(institutionId) {
                let params = {
                    institutionId:institutionId,
                    isFiled: null
                }
                console.log(params)
                this.$api.sendingP2p.getBatch(params)
                    .then(res => {
                        console.log(res.data.data);
                        this.batchList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                    });
            },
            //机构选择改变
            handleInstitution(val) {
                this.search.batchId = '';
                if (val == '') {
                    this.batchShow = true;
                    return;
                }
                this.batchShow = false;
                this.getBatch(val);
            },
            // 获取所有批次
            getDistributionBatchList(){
                let params ={
                    // institutionName:this.search.institutionName,
                    // batchName:this.search.batchName,
                    institutionId: this.search.institutionId,
                    batchId: this.search.batchId,
                    isFiled: this.search.isFiled
                };
                console.log(params);
                this.$api.batchManagement.getDistributionBatchList(params)
                    .then(res => {
                        if(res.data.code == 200){
                            console.log(res.data);
                            this.distributionBatchList = res.data.data;
                        }else{
                            this.$message.warning('服务器维护');
                        }
                    })
            },
            // 获取批次下用户
            getDistributionUsers(row){
                console.log(row);
                let params = {
                    batchId: row.batchId
                }
                this.$api.batchManagement.getDistributionUsers(params)
                    .then(res => {
                        if(res.data.code == 200){
                            console.log(res);
                            this.distributionUserList = res.data.data;
                            this.selectedbatchId = row.batchId
                        }else{
                            this.$message.warning('服务器维护')
                        }
                    })
            },
            removeUser(){
                let ids = [];
                let checkBox = this.$refs.myTable.getCheckboxRecords();
                console.log(checkBox);
                for(let i=0;i<checkBox.length;i++){
                    ids.push(checkBox[i].sysUserId);
                }
                console.log(ids);
                console.log(this.selectedbatchId);
                console.log(ids.length);
                if(ids.length == 0){
                    this.$message.warning('请至少选择一个用户')
                }else{
                    let params = {
                        "batchId": this.selectedbatchId,
                        "distributionUsersList": ids
                    }
                    this.$api.batchManagement.deleteUserDistribution(params)
                        .then(res => {
                            if(res.data.code == 200){
                                this.$message.success('移除成功');
                                let params2 = {
                                    batchId: this.selectedbatchId
                                }
                                this.$api.batchManagement.getDistributionUsers(params2)
                                    .then(res => {
                                        if(res.data.code == 200){
                                            console.log(res);
                                            this.distributionUserList = res.data.data;
                                        }else{
                                            this.$message.warning('服务器维护')
                                        }
                                    })
                            }else{
                                this.$message.warning('服务器维护')
                            }
                        })
                }
            }
        },
        components: {

        }
    }
</script>

<style scoped>

</style>

<style>
    /* .selectedAuth .el-card__body {
        padding: 0px;
        overflow: auto;
    }

    .authBox .el-card__body {
        height: calc(100vh - 350px);
        overflow: auto;
    } */
</style>