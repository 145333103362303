<template>
    <div class='app-container'>
        <!-- <el-page-header @back="goBack" content="角色管理"></el-page-header> -->

        <el-divider class="margin20"></el-divider>

        <!-- <el-alert title="创建策略需要先选择相应的账号类型，再对其类型创建相应的权限策略" type="info" :closable='false' show-icon>
        </el-alert> -->

        <el-tabs v-model="activeName" @tab-click="tabClickHandle" class="el-tabs_self">
            <!-- <el-tab-pane v-for="item in roles" :key="item.roleId" :label="item.description" :name="item.description">
                <create-auth :roleId='item.roleId'></create-auth>
            </el-tab-pane> -->
            <el-tab-pane label="角色视角" name="first">
                <user-view v-if="selected == 0"></user-view>
            </el-tab-pane>
<!--            <el-tab-pane label="批次视角" name="second" >-->
<!--                <batch-view v-if="selected == 1"></batch-view>-->
<!--            </el-tab-pane>-->
        </el-tabs>
    </div>


</template>

<script>
    import UserView from '@/components/workDistribution/UserView'
    import BatchView from '@/components/workDistribution/BatchView'
    export default {
        data() {
            return {
                activeName: 'first',
                roles: [],
                selected:0
            }
        },
        created() {
            // this.getPreviewRole();
        },
        methods: {
            tabClickHandle(tab,event){
                console.log(tab.index);
                this.selected = tab.index
            },
        },
        components: {
            UserView,
            BatchView
        }
    }
</script>

<style scoped>
    .el-tabs_self {
        margin-top: 10px;
    }

    .authBox .el-card__body {
        height: calc(100vh - 300px);
        overflow: auto;
    }
</style>

<style>
    .margin20.el-divider--horizontal {
        margin: 20px 0px;
    }

    .selectedAuth .el-card__body {
        padding: 0px;
        padding-bottom: 40px;
        overflow: auto;
    }

    .authBox .el-card__body {
        height: calc(100vh - 350px);
        overflow: auto;
    }
</style>